<template>
    <vue-tel-input 
        v-model="value"
        mode="international"
        :onlyCountries="allowedCountries"
        :inputOptions="contactFieldOptions"
        ref="contactNoField"
        :dropdownOptions="contactFieldDropDownOptions"
        :validCharactersOnly=true 
        :disabled="disabled"
        @input="contactInputHandle"
        @validate="validateContactNo"
        @country-changed="countryChangeHandle"
        @focus="contactFieldFocusHandle"
        @open="dropDownOpenHandle"
        @close="dropDownCloseHandle"
    >
    </vue-tel-input>
</template>

<script>
import {mapGetters} from 'vuex';

export default {
    name:'contactInput',
    data(){
        return {
            value: '',
            allowedCountries: [],
            contactFieldOptions: {
                placeholder: '',
                showDialCode: true,
                type: 'tel',
                maxlength: 25,
            },
            contactFieldDropDownOptions:{
                showSearchBox: true,
                showDialCodeInSelection: false,
                showDialCodeInList: true,
                showFlags: true,
                placeholder: "Select a Country"
            },
            contactIsValid: true,
            countryIsSelected: false,
            contactFieldTouched: false,
            selectedContactObj: {},
            selectedContactCountry: [],
            countryObjBeforeChange: [],
        }
    },
    props:{
        contactNo:{
            type: String,
            required: true,
            description: 'contact no.'
        },
        placeHolder:{
            type: String,
            required: true,
            description: 'placeholder for field'
        },
        disabled:{
            type: Boolean,
            default: false
        },
        setValidations:{
            type: Function
        },
    },
    watch:{
        storeAllCountries:{
            handler:function(value){

                value.forEach((country)=>{

                    this.allowedCountries.push(country.iso2);

                });
                

            },deep:true
        },
        contactNo:{
            handler:function(value){

                this.value = value;

            },deep:true
        },
        placeHolder:{
            handler:function(value){

                this.contactFieldOptions.placeholder = value;

            },deep:true
        },
    },
    computed:{
        ...mapGetters({
            settings:'settings_module/settings',
            storeAllCountries: 'countries_module/allCountries'
        }),
    },
    methods:{
        contactInputHandle(number,numberObj){

            if(typeof numberObj.country == 'undefined'){
        
                this.countryIsSelected = false;

            }else if(typeof numberObj.countryCode != 'undefined' && numberObj.countryCode == "--"){
                
                this.countryIsSelected = false;

            }else if(typeof numberObj.country != 'undefined' && 
            typeof numberObj.valid == 'undefined' &&
            numberObj.formatted.length == numberObj.country.dialCode + 1){
                
                this.countryIsSelected = false;

            }else{

                this.countryIsSelected = true;
                
            }

            this.selectedContactObj = numberObj;

            this.handleValidationChange();

        },
        validateContactNo(contactObj){

            if(typeof contactObj.valid != 'undefined'){

                this.contactIsValid = contactObj.valid;

            }

            this.handleValidationChange();

        },
        countryChangeHandle(countryObj){

            this.selectedContactCountry = countryObj;

        },
        dropDownOpenHandle(){

            this.countryObjBeforeChange = this.selectedContactObj;
            
        },
        dropDownCloseHandle(){

            if((this.value).replace(/\s/g,'').length > this.selectedContactCountry.dialCode.length + 1){

                if((this.countryObjBeforeChange.country != this.selectedContactObj.country)){

                    this.value = "+" + this.selectedContactCountry.dialCode;

                }

            }

            this.countryIsSelected = true;

            this.handleValidationChange();
            
        },
        contactFieldFocusHandle(){

            this.contactFieldTouched = true;

            this.handleValidationChange();

        },
        handleValidationChange(){

            let validations = {
                isContactValid: this.contactIsValid,
                isCountrySelected: this.countryIsSelected,
                isFieldTouched: this.contactFieldTouched,
                contactObj: this.selectedContactObj,
                addContactNo: this.value
            }

            this.setValidations(validations);

        },
        resetValues(){

            this.contactIsValid = true;
            this.countryIsSelected = false;
            this.contactFieldTouched = false;
            this.selectedContactObj = {};
            this.selectedContactCountry = [];
            this.countryObjBeforeChange = [];

            this.handleValidationChange();

        }
    },
    mounted(){

        if(this.placeHolder){

            this.contactFieldOptions.placeholder = this.placeHolder;

        }

    },
    beforeMount(){

        if(!this.storeAllCountries || this.storeAllCountries.length == 0){

            this.$store.dispatch('countries_module/fetchAllCountries');

        }else{

            this.storeAllCountries.forEach((country)=>{

                this.allowedCountries.push(country.iso2);

            });

        }

    }
}
</script>

<style>

</style>